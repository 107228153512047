import React, { useEffect } from 'react'
import './badge.css'
import badge from '../../assets/badge.png'
import Tamu from '../../assets/tamu.png'
import Resi from '../../assets/Resi.png'
import Bnf from '../../assets/BNFHanAra.png'
import AOS from "aos";
import "aos/dist/aos.css";

const Badge = () => {
  useEffect(() => {
    AOS.init({      
      duration: 1000,
      easing: "ease-out-cubic",
    });
  }, []);
      
  return (    
    
    <section id ='badge'  data-aos="fade-up" >
        <div className="container portfolio__container"> 
            <h5>Work & School & Cetification</h5>
            <h2>My Badges</h2>
            <div className='port_imgs'>
                <img className='badge' src={badge} data-aos="fade-up" data-aos-duration="1000"/>          
                <img className='Tamu' src={Tamu} data-aos="fade-up" data-aos-duration="1000"/>          
                <img className='Resi' src={Resi} data-aos="fade-up" data-aos-duration="1000"/>          
                <img className='Bnf' src={Bnf} data-aos="fade-up" data-aos-duration="1000"/>          
            </div>
            <div className='genline' data-aos="fade-up"></div>
        </div>
    </section>
  )
}

export default Badge