import React from 'react'
import './contact.css'
import {MdOutlineEmail} from 'react-icons/md'
import {BsLinkedin} from 'react-icons/bs'
import {useRef, useEffect} from 'react';
import emailjs from 'emailjs-com';
import AOS from "aos";
import "aos/dist/aos.css";

const Contact = () => {
  const form = useRef();

  useEffect(() => {
    AOS.init({      
      duration: 1000,
      easing: "ease-out-cubic",
    });
  }, []);
      
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_0liv62h', 'template_91214ji', form.current, 'F_fSDBt338EoHHg7a')

    e.target.reset()
  };
  return (
    <section id='contact'>
      <h5 data-aos="fade-up">Get In Touch</h5>
      <h2 data-aos="fade-up">Contact Me</h2>

      <div className="container contact__container" data-aos="fade-up" data-aos-offset="300">
        <div className="contact__options">
          <article className="contact__option">
            <MdOutlineEmail/>
            {/* <h4>Email</h4> */}
            <h5>j.seo0917@gmail.com</h5>
            <a href="mailto:j.seo0917@gmail.com" target="_blank">Send an Email</a>
          </article>
          <article className="contact__option">
            <BsLinkedin/>
            {/* <h4>LinkedIn</h4> */}
            <h5>Let's Connect!</h5>
            <a href="https://www.linkedin.com/in/jung-hoon-seo-5ab813178/">Connect with LinkedIn</a>
          </article>
        </div>
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder='Your Full Name' required/>
          <input type="email" name='email' placeholder='Your Email' required/>
          <textarea name="message" rows="7" placeholder='Your Message' required></textarea>
          <button type='submit' className='btn btn-primary'>Send Message</button>
        </form>
      </div>
    </section>
  )
}

export default Contact