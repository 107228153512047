import React, { useEffect, useState } from 'react';
import './portfolio.css'
import AOS from "aos";
import "aos/dist/aos.css";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import {FaGithub} from 'react-icons/fa'
import { FaYoutube } from "react-icons/fa";
import { GrDocumentDownload } from "react-icons/gr";
import WorkItem from './WorkItems.jsx';
import Pagination from './pagination';

const data = [

    {
      id: 11,      
      title: 'Spring Boot Microservice',
      description: 'Developed microservices with Spring Boot and Spring Cloud to manage hierarchical organizational structures, including branching across multiple organizations, departments, and employees, all integrate with an API Gateway for efficient request handling.',      
      github: "https://github.com/jseo0917/Springboot-microservice/",
      grDoc: null,        
      demo: null,        
      categories: ['Java', 'Spring', 'Micro service', 'REST API', 'React']
    } 
  ,  {
        id: 10,      
        title: 'Learning-augmented list',
        description: 'We integrate machine learning predictions into skip list design, creating a structure that achieves near-optimal expected search times, even with potentially inaccurate oracle estimations. Our learning-augmented skip list, particularly effective under Zipfian distributions, shows constant expected search times and outperforms traditional skip lists on various datasets, maintaining robustness even with incorrect predictions.',      
        github: null,
        grDoc: "https://arxiv.org/pdf/2402.10457",        
        demo: null,        
        categories: ['Python', 'Research', 'ML', 'Skip List']
      }
    ,
    {
      id: 9,      
      title: 'Spring Boot Blog REST API',
      description: 'Developed a Spring Boot REST API in Java for the blog management system using MySQL and enhanced database security with authentication and authorization through Spring Security and JWT tokens. ',      
      github: "https://github.com/jseo0917/springboot-blog-RESTAPI",
      grDoc: null,        
      demo: null,        
      categories: ['Spring', 'MySQL', 'Java', 'REST API', 'JWT', 'Security']
    }
    ,
    {
        id: 8,      
        title: 'Dialog Summarization for Customer Service via Chat',
        description: 'Dialog summarization, especially in customer service, requires quickly capturing key information such as the problem, solution, and customer requests. This paper aims to support agents by developing a model that can effectively extract and provide automated real-time summaries from conversation records to minimize customer waiting time.',      
        github: "https://github.com/jseo0917/NLP_GROUP_PROJECT",
        grDoc: null,
        demo: "https://drive.google.com/file/d/1hySrzQ1ghk4TP_zok_NV4jzgd8_eGHV0/view",
        categories: ['Python', 'Chat', 'NLP']
      },
      {
        id: 7,      
        title: 'Recommender System',
        description: 'Implemented recommender systems with 1M MovieLens datasets using baseline estimation model, collaborative filtering, and matrix factorization to estimate user’s rating for unwatched movies.',      
        github: "https://github.com/jseo0917/CSCE676_DATA_MINING/blob/master/HW3/Recommender_Sys.ipynb",
        grDoc: null,
        demo: null,
        categories: ['Python', 'Rec System', 'Data Mining']
      },
      {
        id: 6,      
        title: 'Credit Card Fraud Detection with an ensemble learner',
        description: 'Unbalanced data often leads to low precision and recall but high accuracy; common solutions include under-sampling the majority class or oversampling the minority class, both of which have drawbacks. This paper found under-sampling more effective and constructed an ensemble learner to mitigate its side effects, improving precision and recall while maintaining high accuracy, using a Kaggle dataset with 284,807 transactions, including 492 fraudulent ones.',      
        github: "https://github.com/jseo0917/Overcoming-High-Accuracy-coupled-with-Low-Precision-and-Recall-in-Credit-Card-Fraud-Detection/blob/master/Overcoming%20High%20Accuracy%20coupled%20with%20Low%20Precision%20and%20Recall%20in%20Credit%20Card%20Fraud%20Detection.ipynb",
        grDoc: "https://drive.google.com/file/d/1m4nnGqUdIsiEbhOaGto0z3jsUV1ATTIq/view",
        demo: null,
        categories: ['Python', 'Fraud', 'Detection', 'ML']
      },    
      {
        id: 5,      
        title: 'CINEFILE',
        description: 'Developed the front-end of a movie search system using ReactJS and integrating it with REST API to fetch and present movie information to users.',      
        github: "https://github.com/jseo0917/CSCE310_CINEFILE",
        grDoc: null,
        demo: null,
        categories: ['ReactJs', 'Movie App', 'REST API']
      },  
      {
        id: 4,      
        title: 'Apriori Algorithm',
        description: 'Implemented Apriori Algorithm using Python with MovieLens datasets and generated association rules to predict user interest in similar movies.',      
        github: "https://github.com/jseo0917/CSCE676_DATA_MINING/blob/master/HW0/827009126_0.ipynb",
        grDoc: null,
        demo: null,
        categories: ['Data Mining', 'Apriori', 'Algorithm', 'Python']
      },   
      {
        id: 3,      
        title: 'Perceptron',
        description: 'Implemented percentron using ML technology. Successfully classified two different classes.',      
        github: "https://github.com/jseo0917/CSCE633_MACHINE_LEARNING/blob/master/Perceptron/Perceptron.ipynb",
        grDoc: null,
        demo: null,
        categories: ['Python', 'Regression', 'ML']
      },   
    {
        id: 2,      
        title: '3D Printing',
        description: 'Using the 3D printer, I designed and created fabricated materials.',      
        github: null,
        grDoc: "https://jseo0917.github.io/index.html",
        demo: null,
        categories: ['3DPrint']
      },
      {
        id: 1,      
        title: 'Operation System',
        description: 'Built a basic operating system using C++ such as memory frame manager, FIFO scheduler',      
        github: "https://github.com/jseo0917/Operating-System",
        grDoc: null,
        demo: null,
        categories: ['C++', 'OS']
      },
      {
        id: 0,      
        title: 'Statistical Analysis of Continuous Glucose Monitoring Data',
        description: 'Improved Iglu package by adding a functionality by creating a glucose level monitoring system. It detect various irregularities in glucose levels for individuals with diabetes, providing summary statistics and visualizations via a Shiny app.',      
        github: "https://github.com/jseo0917/Operating-System",
        grDoc: null,
        demo: "https://drive.google.com/file/d/1eF9lHG3l8C9hT7jiFCjGHZte47GgonYr/view",
        categories: ['R', 'Monitoring', 'Iglu']
      },
]

const Portfolio = () => {
  useEffect(() => {
    AOS.init({            
    });
  }, []);

  const [isActive, setIsActive] = useState(false);
  const [selected, setSelected] = useState("Subject");
  
  const subjects = [<Dropdown.Item href="#/action-1">C++</Dropdown.Item>,
  <Dropdown.Item href="#/action-2">AI/Data</Dropdown.Item>,
  <Dropdown.Item href="#/action-3">AWS</Dropdown.Item>];

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(4);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstpost = indexOfLastPost - postsPerPage;
  const currentPosts = data.slice(indexOfFirstpost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <section id="portfolio">  
      <div className="container portfolio__container">
        <h5 data-aos="fade-up">My Recent Work</h5>
        <h2 data-aos="fade-up">Portfolio</h2>
        <div className='work-wrapper' data-aos="fade-up" data-aos-delay="50" data-aos-duration="1000" >        
            <div className='btn-area'>                                
                {/* <DropdownButton className= 'button' title={selected} onClick={e => setIsActive(!isActive) }>
                    {isActive && (
                        <dev className='dropdown-content'>
                            {subjects.map((option) => (
                                <div onClick={(e) => {
                                    setSelected(option) 
                                    setIsActive(false)
                                }} className='dropdown-item'>{option}</div>
                            ))}                            
                        </dev>)}          
                </DropdownButton>                     
                <button className='button'>Language</button>  
                <button className='button'>Search</button>   */}
            </div>
            
            {currentPosts.map(({id, title, description, grDoc, github, demo, categories}) => {
                return(
                    <div className='work-list' data-aos="fade-up" >
                        <div className='work-list-container'>
                            <div className='work-list-items'>
                                <div className="work-title">
                                    {title}
                                    {categories.map( (item) => {
                                        return(<div className="work-category">{item}</div>)
                                    })}                                                                  
                                </div>
                                    <p>{description}</p>                        
                                
                                <dev className='work-list-icons'>  
                                    {(github !== null)?                                                                                   
                                    <a href={github} className="temp" target='_blank'><FaGithub                                        
                                        className = "github"                                        
                                    /></a> : <></>}
                                    {(grDoc !== null)?                                                                                   
                                    <a href={grDoc} className="temp" target='_blank'><GrDocumentDownload                                        
                                        className = "grDoc"                                        
                                    /></a> : <></>}
                                    {(demo !== null)?                                                                                   
                                    <a href={demo} className="temp" target='_blank'><FaYoutube                                        
                                        className = "demo"                                        
                                    /></a> : <></>}
                                </dev>
                            </div>                                
                        </div>        
                    </div>     
                    
                )
            })}
            <Pagination postsPerPage={postsPerPage} totalPosts={data.length} currentPage={currentPage} paginate={paginate}/>            
        </div>            
            
      </div>        
    </section>
  )
}

export default Portfolio