import React, { useEffect } from 'react'
import './about.css'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'
import { IoIosSchool } from "react-icons/io";
import { FaRegHandshake } from "react-icons/fa";
import { GiPublicSpeaker } from "react-icons/gi";
import AOS from "aos";
import "aos/dist/aos.css";

const About = () => {
  useEffect(() => {
    AOS.init({      
      duration: 1000,
      easing: "ease-out-cubic",
    });
  }, []);
      
  return (
    // <section id ='about' data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1000">
    <section id ='about'  data-aos="fade-up" >
      <h5>Get To Know</h5>
      <h2>About Me</h2>
      <div className="container about__container">

        <div className="about__content">  
            <p className="message_POP">Jung has joined the conversation.</p>                                
            <div class="col-sm-6 mb-11">
                <div class="card" data-aos="zoom-in-right">
                    <div class="card-body-left">
                        <p>Hi Jung, tell me about your self.</p>
                    </div>
                </div>
            </div>                  

            <div class="col-sm-6 mb-11">
                <div class="card" data-aos="zoom-in-left" data-aos-offset="350">
                    <div class="card-body-right">
                        <p>I graduated from Texas A&M University in 2023 with a Master's degree in Computer Science. I am currently working at HanAra Software in Austin, where I develop OPC UA Server/Client solutions.</p>
                    </div>
                </div>
            </div> 

            <div class="col-sm-6 mb-11">
                <div class="card" data-aos="fade-right">
                    <div class="card-body-left" data-aos="fade-right" data-aos-offset="330">
                        <p>Cool, what kind of skills do you have?</p>
                    </div>
                </div>
            </div>                  

            <div class="col-sm-6 mb-11">
                <div class="card" data-aos="fade-left">
                    <div class="card-body-right" data-aos="fade-left" data-aos-offset="330">
                        <p>I have experience in back-end development, including API, server, and client development. Also, I have developed many applications, giving me comprehensive knowledge of the software development process.</p>
                    </div>
                </div>
            </div> 

            <div class="col-sm-6 mb-11">
                <div class="card" data-aos="fade-right">
                    <div class="card-body-left" data-aos="fade-right" data-aos-offset="330">
                        <p>What other skills do you have?</p>
                    </div>
                </div>
            </div>                  

            <div class="col-sm-6 mb-11">
                <div class="card" data-aos="fade-left">
                    <div class="card-body-right" data-aos="fade-left" data-aos-offset="330">
                        <p>With many companies moving to the cloud, I recently obtained AWS Certified Developer certification to gain a solid understanding of cloud technologies and their applications. 
                            I possess data analytics skills in machine learning and natural language processing. Please reference below for my projects.
                        </p>
                    </div>
                </div>
            </div> 

            <div class="col-sm-6 mb-11">
                <div class="card" data-aos="fade-right">
                    <div class="card-body-left" data-aos="fade-right" data-aos-offset="330">
                        <p>Great!</p>
                    </div>
                </div>
            </div>       
            <div class="col-sm-6 mb-11">
                <div class="card" data-aos="fade-left">
                    <div class="card-body-right" data-aos="fade-left" data-aos-offset="330">
                        <p>For more information, please look at my portfolio and feel free to contact me through the email below.                           
                        </p>
                    </div>
                </div>
            </div>            
        </div>
      </div>
    </section>
  )
}

export default About