import React from 'react';

const Pagination = ({postsPerPage, totalPosts, currentPage, paginate}) => {

    const pageNumbers = [];

    for(let i = 1; i <= Math.ceil(totalPosts/postsPerPage); i++){
      pageNumbers.push(i);
    }

    console.log(currentPage)
    return(
        <nav>
            <ul className='pagination'>
                {pageNumbers.map(number => (
                    <li key={number} className='page-item'>
                        {(currentPage === number )? 
                            <a style={{color: "blue", fontWeight:"bold"}} onClick={() => paginate(number)}>{number}</a> : 
                            <a onClick={() => paginate(number)}>{number}</a>}
{/* 
                        <a onClick={() => paginate(number)} className = 'page-link'>
                            {number}
                        </a> */}
                    </li>    
                ))}
            </ul>
        </nav>
    )
}

export default Pagination;