import React from 'react'
import './footer.css'
import {BsLinkedin} from 'react-icons/bs'
import {FaGithub} from 'react-icons/fa'
import ME from '../../assets/treewhite.png'
const Footer = () => {  
    return (
      <footer>
        
            {/* <img className="tree-img" src={ME} data-aos="fade-up" data-aos-duration="1000" alt="me"/>           */}                
        <ul className='permalinks'>
          <li><a href="#">Home</a></li>
          <li><a href="#about">About</a></li>
          <li><a href="#experience">Skills</a></li>
          <li><a href="#portfolio">Portfolio</a></li>
          <li><a href="#contact">Contact</a></li>          
        </ul>
        <a href="#" className='footer-link footer-link-tree'></a>
{/*       
        <div className="footer__socials">
          <a href="https://www.linkedin.com/in/jung-hoon-seo-5ab813178/" target="_blank"><BsLinkedin/></a>
          <a href="https://github.com/jseo0917?tab=repositories" target="_blank"><FaGithub/></a>
        </div> */}
        <div className="footer__copyright">
          <small>&copy; All rights reserved.</small>
        </div>
      </footer>

    )  
}

export default Footer