import React, { useEffect } from 'react'
import './header.css'
import CV from "../../assets/cv.pdf"
import ME from '../../assets/me2.jpeg'
import MyJourney from '../../assets/myjourney.gif'
// import ME from '../../assets/treewhite.png'
import AOS from "aos";
import "aos/dist/aos.css";

const Header = () => {
  useEffect(() => {
    AOS.init({               
    });
  }, []);
    
  return (
    <header>      
      <div id= "header" data-aos="zoom-y-out" data-aos-delay="50" className="container header__container">
        <h4>Welcome! I'm</h4>
        <h1>Jung Hoon Seo</h1>
        <h5 className="text-light">Software Engineer</h5>                        
        <a href={CV} download className="me">
          <img className="tree-img" src={MyJourney} data-aos="fade-up" data-aos-duration="1000" alt="me"/>          
        </a>                
        <h5 className="me_description" data-aos="fade-up" data-aos-duration="1000">I am currently working as a software engineer in Austin. 
        <br/>Just as a tree provides increasing value to humans as it grows, 
        <br/>I aim to deliver greater value to customers, companies, and people around the world as I grow professionally.
        <br/><a href={CV} download>Click Here to download my resume</a></h5>
      </div>        
    </header>
  )
}

export default Header