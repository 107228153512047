import Navbar from './components/Navbar/Navbar'
import Header from './components/header/Header'
import About from './components/about/About'
import Portfolio from './components/portfolio/Portfolio'
import Badge from './components/Badge/Badge'
import Contact from './components/contact/Contact'
import Footer from './components/footer/Footer'
const App = () => {
    return (
        <>
            <Navbar/>
            <Header /> 
            <About/>
            <Portfolio/>
            <Badge/>
            <Contact/>
            <Footer/>
        </>           
        )
    }

export default App