import React from 'react'
import LOGO from '../../assets/treewhite.png'
import './navbar.css'
const Navbar = () => {
  return (
    <div className="header">
      {/* <a href="#" className="header__logo">{LOGO}</a> */}
      {/* <a href="#"><img src={LOGO} className="header__logo"/></a> */}
      <div className="navbar">    
           {/* Mobile         */}
           <ul className="nav-list nav-list-mobile">
                <li className='nav-item'>
                    <div className="mobile-menu">
                        <span className="line line-top"></span>
                        <span className="line line-bottom"></span>
                    </div>
                </li>
                <li className='nav-item'>
                    <a href="#" className='nav-link nav-link-tree'></a>
                </li>
           </ul>

           {/* nav-list nav-list-larger */}
           <ul className="nav-list nav-list-larger">                
                <li className='nav-item nav-item-hidden'>
                    <a href="#" className='nav-link nav-link-tree'></a>
                </li>

                <li className='nav-item'>
                    <a href="#" className='nav-link'>Home</a>
                </li>
                <li className='nav-item'>
                    <a href="#about" className='nav-link'>About</a>
                </li>
                {/* <li className='nav-item'>
                    <a href="#" className='nav-link'>Skills</a>
                </li> */}
                <li className='nav-item'>
                    <a href="#portfolio" className='nav-link'>Portfolio</a>
                </li>
                <li className='nav-item'>
                    <a href="#contact" className='nav-link'>Contact</a>
                </li>
           </ul>
      </div>
    </div>
  )
}


export default Navbar